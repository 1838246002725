import { API } from '@indieocean/apidef'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useURLParam } from '../../Utils/UseURLParam'
import { AppPage } from '../Common/Page/AppPage'
import { useAppPathFn } from '../Common/Tools/UseAppPathFn'
import { isUserWithLiveStore, useFUser } from '../Common/WithUser'

export const Login = React.memo(() => {
  return (
    <AppPage
      title="Login to IndieOcean"
      header={null}
      nav={{currentPage: 'Account'}}
      showGoogleSignInPrompt="alreadySignedIn"
    >
      <_AfterData />
    </AppPage>
  )
})

const _AfterData = React.memo(() => {
  const router = useRouter()
  const user = useFUser()
  const dest =
    useURLParam('dest') ?? (isUserWithLiveStore(user) ? '/home' : '/explore')

  const pathFn = useAppPathFn()

  useEffect(() => {
    void router.replace(dest.startsWith('/logout') ? pathFn(API.Path.root) : dest)
  }, [dest, router, pathFn])

  return <></>
})
