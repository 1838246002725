import {faGoogle, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {API} from '@indieocean/apidef'
import {sendSignInLinkToEmail} from 'firebase/auth'
import {useRouter} from 'next/router'
import React from 'react'
import {graphql} from 'react-relay'
import {Config} from '../../../../Config'
import {firebaseAuth} from '../../../../Remote/Firebase'
import {useBooleanStateObj} from '../../../Utils/UseBooleanStateObj'
import {InputControl} from '../../../Utils/UseInputControl'
import {TextInput} from '../../Form/TextInput'
import {CenteredCircleProgress} from '../../Tools/CenteredCircleProgress'
import {useGQLMutation} from '../../Tools/UseGQLMutation'
import {DoLoginIdleOrRunningEmailMutation} from './__generated__/DoLoginIdleOrRunningEmailMutation.graphql'
import {DoLoginIdleOrRunningTwitterMutation} from './__generated__/DoLoginIdleOrRunningTwitterMutation.graphql'

const emailMutation = graphql`
  mutation DoLoginIdleOrRunningEmailMutation($args: UserLoginPreflightArgs!) {
    userLoginPreflight(args: $args) {
      __typename
    }
  }
`
const twitterMutation = graphql`
  mutation DoLoginIdleOrRunningTwitterMutation(
    $args: UserLoginTwitterRequestTokenArgs!
  ) {
    userLoginTwitterRequestToken(args: $args) {
      token
      tokenSecret
    }
  }
`

export const DoLoginIdleOrRunning = React.memo(
  ({
    children,
    emailControl,
    onDone,
  }: {
    emailControl: InputControl
    children: string
    onDone: () => void
  }) => {
    const router = useRouter()
    const [commitEmail, isEmailRunning] =
      useGQLMutation<DoLoginIdleOrRunningEmailMutation>(emailMutation, 'soft')

    const isSocialRunning = useBooleanStateObj(false)
    const [commitTwitter] = useGQLMutation<DoLoginIdleOrRunningTwitterMutation>(
      twitterMutation,
      'soft'
    )

    const isRunning = isEmailRunning || isSocialRunning.value
    const dest = `${window.location.pathname}${window.location.search}`

    const handleEmail = () => {
      if (!emailControl.validation.ok) {
        emailControl.setValidating(true)
        return
      }
      const email = emailControl.validation.result
      commitEmail({
        variables: {args: {email: emailControl.validation.result}},
        onCompleted: () => {
          void sendSignInLinkToEmail(firebaseAuth, email, {
            url: Config.client.urls.app(API.Path.auth().byEmail({dest, email})),
            handleCodeInApp: true,
          })
          onDone()
        },
      })
    }

    const handleTwitter = () => {
      isSocialRunning.setTrue()
      commitTwitter({
        variables: {args: {dest}},
        onCompleted: ({userLoginTwitterRequestToken: result}) => {
          const url = new URL('https://api.twitter.com/oauth/authenticate')
          url.searchParams.set('oauth_token', result.token)
          void router.push(url)
        },
        onError: isSocialRunning.setFalse,
      })
    }

    const googleParams = new URLSearchParams({
      client_id: Config.client.connections.googleOAuth.clientId,
      redirect_uri: Config.client.urls.app(API.Path.auth().byGoogle),
      response_type: 'code',
      state: dest,
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      // access_type: 'online',
      include_granted_scopes: 'true',
    })

    return (
      <>
        <div className={isRunning ? 'opacity-20 pointer-events-none' : ''}>
          <h1 className="form-title">Continue with</h1>
          <div className="grid grid-flow-row justify-start gap-y-4">
            <button
              className="grid grid-flow-col w-36 justify-center items-center gap-x-2 btn-md btn-dark "
              onClick={handleTwitter}
            >
              <span className="text-2xl">
                <FontAwesomeIcon icon={faTwitter} />
              </span>
              Twitter
            </button>
            <a
              className="grid grid-flow-col w-36 justify-center items-center gap-x-2 btn-md btn-dark"
              href={`https://accounts.google.com/o/oauth2/v2/auth?${googleParams.toString()}`}
            >
              <span className="text-2xl">
                <FontAwesomeIcon icon={faGoogle} />
              </span>
              Google
            </a>
          </div>

          <TextInput
            className="mt-10"
            placeholder="email@domain.com"
            disabled={isRunning}
            type="email"
            autoComplete="email"
            control={emailControl}
            onNext={handleEmail}
          >
            {children}
          </TextInput>

          <button
            className="grid grid-flow-col w-36 justify-center items-center gap-x-2 btn-md btn-dark  mt-6"
            onClick={handleEmail}
          >
            <span className="text-2xl">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            Email
          </button>
        </div>
        {isRunning && <CenteredCircleProgress />}
      </>
    )
  }
)
