import { API } from '@indieocean/apidef'
import { noCase } from '@indieocean/utils'
import React, { useState } from 'react'
import { useInputControl } from '../../../Utils/UseInputControl'
import { DoLoginIdleOrRunning } from './DoLoginIdleOrRunning'
import { DoLoginEmailSent } from './DoLoginSent'

const decoders = API.ArgDecoders.userLoginPreflight.parts
export const DoLogin = React.memo(({children}: {children: string}) => {
  const [state, setState] =
    useState<'idleOrRunning' | 'emailSent'>('idleOrRunning')
  const email = useInputControl('', decoders.email, x => x.toLocaleLowerCase())

  const body = (() => {
    switch (state) {
      case 'idleOrRunning':
        return (
          <DoLoginIdleOrRunning
            emailControl={email}
            onDone={() => setState('emailSent')}
          >
            {children}
          </DoLoginIdleOrRunning>
        )
      case 'emailSent':
        return (
          <DoLoginEmailSent
            email={email.value.trim()}
            handleResend={() => setState('idleOrRunning')}
          />
        )
      default:
        noCase(state)
    }
  })()
  return <div className="w-full">{body}</div>
})
