import React from 'react'

export const DoLoginEmailSent = React.memo(
  ({email, handleResend}: {email: string; handleResend: () => void}) => (
    <>
      <div className="form-title-sm">{email}</div>
      <div className="font-karla text-lg  mt-1">
        We just sent you an email. Please click on the link in the email to sign
        in.
      </div>
      <button className="mt-10 btn-md btn-outline" onClick={handleResend}>
        Resend Email
      </button>
    </>
  )
)
