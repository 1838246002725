import React, { ReactNode } from 'react'
import { AppPage } from '../Page/AppPage'
import { useFirebaseUser } from '../WithFirebaseUser'
import { DoLogin } from './DoLogin/DoLogin'

const defaultMessage = ` IndieOcean uses secure passwordless login using only your email address. You will never have to remember a password with us.`

export const RequireAuth = React.memo(
  ({
    message = defaultMessage,
    children,
  }: {
    message?: string
    children: ReactNode
  }) => {
    const firebaseUser = useFirebaseUser()
    if (!firebaseUser) {
      return (
        <AppPage
          title="Login to IndieOcean"
          header={null}
          nav={{currentPage: 'Login'}}
          showGoogleSignInPrompt
        >
          <div className="body-grid3">
            <div className="body-grid-content flex flex-col justify-center items-center">
              {<DoLogin>{message}</DoLogin>}
            </div>
          </div>
        </AppPage>
      )
    }
    return <>{children}</>
  }
)
